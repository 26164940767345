<template>
  <div class="bg-white shadow-sm rounded-lg p-4">

    <div class="row mb-2">
      <div class="col-3 ml-0 pr-2">
        <multiselect
          class="multiselect-top"
          v-model="officeFilter"
          @input="filterData"
          :options="offices"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Select Offices"
          label="office_name"
          track-by="id"
          :select-label="''"
          :deselect-label="''"
          @open="openBackgroundOpacity"
          @close="closeBackgroundOpacity"
        >
          <template slot="selection" slot-scope="{ values, search, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{
                values
                  .map((item) => {
                    return item.office_name;
                  })
                  .join(", ")
              }}</span
            ></template
          >
        </multiselect>
      </div>
      <div class="col-3 ml-0 pr-2">
        <date-picker type="date" v-model="dateFilter" @change="filterData" range format="YYYY/MM/DD" placeholder="Created At"></date-picker>
      </div>
    </div>

    <el-table
      :data="
        this.tableData.filter(
          (data) =>
            !search ||
            data.title.toLowerCase().includes(search.toLowerCase()) ||
            data.shareholder.name.toLowerCase().includes(search.toLowerCase()) ||
            data.author.name.toLowerCase().includes(search.toLowerCase())
        )
      "
      class="w-100"
    >
      <el-table-column
        prop="shareholder.name"
        label="User"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
            {{ scope.row.shareholder?scope.row.shareholder.name + ' ' +scope.row.shareholder.lastName:''}}
        </template>
      </el-table-column>
      <el-table-column
        prop="author.name"
        label="Author"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="title"
        label="Title"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="image" label="Image" width="120">
        <template slot-scope="scope">
          <a target="_blank" :href="scope.row.image">
            <img class="img-notification" :src="scope.row.image" alt="No Image" />
          </a>
        </template>
      </el-table-column>
      <el-table-column
        prop="shareholder.phone"
        label="Phone"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column width="120" sortable prop="createdAt" label="Created At">
        <template slot-scope="scope">{{
          scope.row.createdAt | moment("MM-DD-YYYY")
        }}</template>
      </el-table-column>
      <el-table-column width="120" sortable prop="sendDate" label="Send Date">
        <template slot-scope="scope">{{
          scope.row.sendDate | moment("MM-DD-YYYY")
        }}</template>
      </el-table-column>
      <el-table-column width="200" label="Operations">
        <template slot="header" slot-scope="scope">
          <el-input
            v-model="search"
            placeholder="Search..."
            class="p-0"
            :key="scope.row"
          />
        </template>
        <template slot-scope="scope">
          <center>
            <el-button-group>
              <el-button
                size="mini"
                icon="el-icon-delete"
                @click="remove(scope.row, scope.$index)"
              ></el-button>
            </el-button-group>
          </center>
        </template>
      </el-table-column>
    </el-table>

    <router-link to="/admin/notification/new">
      <el-button class="fixed-bottom new-register" type="primary"
        >New Notification</el-button
      >
    </router-link>

    <el-button @click="sendQueuedNotifications" class="fixed-bottom new-register" style="margin-left:130px;" type="primary"
        >Send Queued Notifications for Today</el-button
      >

  </div>
</template>

<script>
import notification from "@/services/api/Notification";
import office from "@/services/api/office";
import officeUser from "@/services/api/officeUser";

import Multiselect from "vue-multiselect";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      officeFilter: [],
      offices: [],
      dateFilter:[null,null],
      tableData: [],
      data: [],
      search: "",
    };
  },
  mounted() {
    switch (this.$store.getters.discriminator) {
      case "administrator":
        this.discriminator = "administrator";
        office.get().then((response) => {
          this.offices = response;
        });
        break;
      case "office":
      case "employee": {
        this.discriminator = "office";
        let idOffice = null;
        officeUser.getOffice(this.$store.getters.id).then((response) => {
          this.offices = response.map((item) => {
            idOffice = item.office.id;
            return {
              id: item.office.id,
              office_name: item.office.office_name,
            };
          });
        });
        break;
      }
    }
    notification.getQueued().then((response) => {
      this.data = Object.assign([], response);
      this.tableData = response;
    });
  },
  methods: {
    filterData() {
      let idOffices = this.officeFilter.map((office) => {
        return office.id;
      });

      this.tableData = Object.assign([], this.data);

      if (idOffices.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => idOffices.indexOf(item.shareholder.officeId) != -1
        );
      }

      if(this.dateFilter[0]!=null && this.dateFilter[1]!=null){
        this.tableData=this.tableData.filter(item=>{
          var check = new Date(item.createdAt);
          if(check > this.dateFilter[0] && check < this.dateFilter[1]){
            return true;
          }else{
            return false;
          }
        });
      }


    },
    sendQueuedNotifications() {
      notification.sendQueuedNotifications().then((response) => {
        notification.getQueued().then((response) => {
          this.data = Object.assign([], response);
          this.tableData = response;
        });
      });
    },
    remove(row, index) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#67C23A",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          notification
            .deleteQueued(row)
            .then((response) => {
              this.tableData.splice(index, 1);
            })
            .catch(() => {
              this.$message({
                message: "Opps... Something wrong",
                type: "error",
                customClass: "message-error",
              });
            });
        }
      });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">

.mx-datepicker{
    width:100% !important;
  }

  .mx-input{
    height: 42px !important;
  }
</style>

<style>
.img-notification {
  border: 1px solid #ddd; /* Gray border */
  border-radius: 4px; /* Rounded border */
  padding: 5px; /* Some padding */
  width: 80px; /* Set a small width */
}

/* Add a hover effect (blue shadow) */
.img-notification:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}
</style>